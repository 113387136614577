// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-article-tsx": () => import("./../../../src/layouts/article.tsx" /* webpackChunkName: "component---src-layouts-article-tsx" */),
  "component---src-pages-1-procent-podatku-tsx": () => import("./../../../src/pages/1_procent_podatku.tsx" /* webpackChunkName: "component---src-pages-1-procent-podatku-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artykuly-tsx": () => import("./../../../src/pages/artykuly.tsx" /* webpackChunkName: "component---src-pages-artykuly-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o_nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */)
}

