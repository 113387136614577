module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"environments":["production","development"],"googleAnalytics":{"trackingId":"\"\"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-TLFHF436","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"\"\"","cookieName":"gatsby-gdpr-facebook-pixel"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat","Roboto","Lato"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Magfi księgowość - Magdalena Pol","short_name":"Magfi","start_url":"/","background_color":"#2CAFE6","theme_color":"#2CAFE6","display":"minimal-ui","icon":"src/assets/icon-manifest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"70e663dddbbfcafb93f759bb8504ba14"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
